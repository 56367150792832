// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    webApiBaseUri: 'https://api.efiling.dev.alncorp.com/api', //efiling api
    efilingwebUri: 'https://central.efiling.dev.alncorp.com', //efiling app url
    IdenttywebBaseUri: 'localhost:5000',
    o365ClientID: '252c7cc1-308a-465e-bea8-7d3bded0715b',
    o365RedirectUri: 'https://apps.dev.alncorp.com/apps/office365/configure',
    smartDocketsUri: 'https://smartdockets.dev.alncorp.com/appgateway/home/',
    centralAPIBaseUri: 'https://api.apps.dev.alncorp.com',
    crmtUri: 'https://courtrules.dev.alncorp.com',
    fwfUri: 'https://qa.formsworkflow.com/',
    eDocketsUri:'https://edockets.dev.alncorp.com',
    docketAlertsUri:'http://dev.docketalerts.com',

    stsUrl: 'https://identity.dev.alncorp.com/',
    clientId: 'centralApp',
    redirectRoute: 'apps/dashboards/analytics',
    clientUrl: 'https://apps.dev.alncorp.com',
    silentRenewRoute: 'silent-renew.html',
    logConsoleDebugActive: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
