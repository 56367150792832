import { Component, OnInit } from '@angular/core';
import { ALNIdentityAuthService } from 'app/core/auth/aln-identity/aln-identity-auth.service';
import { environment as env } from 'environments/environment';
import { securityAdminRole } from 'app/core/constants/role.constants';

@Component({
    selector: 'app-subscribe-info-modal',
    templateUrl: './subscribe-info-modal.component.html'
})
export class SubscribeInfoModalComponent implements OnInit {

    constructor(private _alnIdentityAuthService: ALNIdentityAuthService) { }

    get isSecurityAdminRole(): boolean {
        if (!this._alnIdentityAuthService.user) {
            return false;
        }

        const { id: currentClientId } = this._alnIdentityAuthService
            .user.allClients.find(c => c.clientId === env.clientId);

        const result = this._alnIdentityAuthService.user.applicationRoles
            .includes(`${securityAdminRole}_${currentClientId}`);

        return result;
    }

    ngOnInit() {
    }

}
