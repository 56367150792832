export class uscfLoginDTO {
    email: string;
    password: string;
    userName: string;
    fName: string;
    lName: string;
    FirmCompany: string;
    PhoneNumber: string;
    LoginStatusID: number;
    securityquestion: string;
    securityanswer: string;
    profileImageURL: string;
    bearerToken: string;
  }