import { FuseNavigation } from '@fuse/types';
import { environment } from 'environments/environment';
import { changePasswordUri, myProfileUri } from 'app/core/constants/url.constants';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Enterprise Suite',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',
                hidden: true,
                children: [
                    {
                        id: 'analytics',
                        title: 'Analytics',
                        type: 'item',
                        url: '/apps/dashboards/analytics'
                    },
                    {
                        id: 'project',
                        title: 'Project',
                        type: 'item',
                        url: '/apps/dashboards/project'
                    }
                ]
            },
            {
                id: 'mail',
                title: 'Mail 365',
                translate: 'NAV.MAIL.TITLE',
                type: 'item',
                icon: 'email',
                url: '/apps/mail',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.MAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'calendar',
                title: 'Calendar 365',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'today',
                url: '/apps/calendar'
            },
            {
                id: 'contacts',
                title: 'Contacts',
                translate: 'NAV.CONTACTS',
                type: 'item',
                icon: 'contacts',
                url: '/apps/contacts'
            }
        ]
    },
    {
        id: 'Applications',
        title: 'Applications',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'eDockets',
                title: 'eDockets ®',
                type: 'item',
                icon: 'compass_calibration',
                url: `${environment.eDocketsUri}`,
                externalUrl: true,
                openInNewTab: true
            },
            {
                id: 'efiling',
                title: 'E-Filing Portal ®',
                type: 'item',
                icon: 'queue_play_next',
                url: `${environment.efilingwebUri}`,
                externalUrl: true,
                openInNewTab: true
                // children: [
                //         {
                //             id   : 'newfiling',
                //             title: 'New Filing',
                //             type : 'item',
                //             url  : 'http://devfuse.efilingportal.com/new-filing',
                //             externalUrl : true,
                //             hidden : true
                //         },
                //         {
                //             id   : 'subsequentfiling',
                //             title: 'Subsequent Filing',
                //             type : 'item',
                //             url  : 'http://devfuse.efilingportal.com/subsequent-filing',
                //             externalUrl : true,
                //             hidden : true
                //         }
                //     ]
            },
            {
                id: 'smart-dockets',
                title: 'Smart Dockets ®',
                type: 'item',
                icon: 'fiber_smart_record',
                url:`${environment.smartDocketsUri}`,
                // url: '/apps/appgateway/null/smartdockets',
                externalUrl: true,
                openInNewTab: true
            },
            {
                id: 'court-rules',
                title: 'Court Rules',
                type: 'item',
                icon: 'account_balance',
                url: `${environment.crmtUri}`,
                //url: 'http://qa.courtrulestool.com',
                externalUrl: true,
                openInNewTab: true
            },
            {
                id: 'forms-workflow',
                title: 'Forms Workflow ®',
                type: 'item',
                icon: 'find_in_page',
                url: `${environment.fwfUri}`,
                externalUrl: true,
                openInNewTab: true
            },
            {
                id: 'DocketAlerts',
                title: 'Docket Alerts ®',
                type: 'item',
                icon: 'format_textdirection_l_to_r',
                url: `${environment.docketAlertsUri}`,
                externalUrl: true,
                openInNewTab: true
            },
        ]
    },
    {
        id: 'aln-productmanagement',
        title: 'Profile',
        type: 'group',
        children: [
            {
                id: 'profile',
                title: 'Profile',
                type: 'item',
                icon: 'person_outline',
                url: myProfileUri,
                externalUrl: true
            },
            {
                id: 'change-password',
                title: 'Change Password',
                type: 'item',
                icon: 'vpn_key',
                url: changePasswordUri,
                externalUrl: true
            }
        ]
    }
];
