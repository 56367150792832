import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { MsalModule } from '@azure/msal-angular';
import { Office365OAuthSettings } from './core/office365auth/office365oauthsettings';
import { environment } from 'environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { environment as env } from '../environments/environment';
import { OidcSecurityService, OidcConfigService, OpenIdConfiguration, ConfigResult } from 'angular-auth-oidc-client';
import { ALNIdentityAuthService } from './core/auth/aln-identity/aln-identity-auth.service';
import { ALNIdentityAuthGuard } from './core/auth/guards/aln-identity-auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { SubscribeInfoModalComponent } from './helpers/components/subscribe-info-modal/subscribe-info-modal.component';
import { CacheControlInterceptor } from './core/http-interceptors/cache-control-interceptor';

export function loadConfig(oidcConfigService: OidcConfigService) {
    return () => oidcConfigService.load_using_stsServer(env.stsUrl);
}

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',
        canActivateChild: [ALNIdentityAuthGuard],
    },
    {
        path: 'dashboard',
        redirectTo: 'apps/dashboards/analytics'
    },
    {
        path: '**',
        loadChildren: './main/apps/apps.module#AppsModule',
        canActivateChild: [ALNIdentityAuthGuard],
    }
];

@NgModule({
    declarations: [
        AppComponent,
        SubscribeInfoModalComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        ToastrModule.forRoot(),
        MsalModule.forRoot({
            clientID: environment.o365ClientID,
            redirectUri: environment.o365RedirectUri,
            navigateToLoginRequestUrl: false
        }
        ),
    ],
    providers: [
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService],
            multi: true,
        },
        ALNIdentityAuthService,
        ALNIdentityAuthGuard,
          {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheControlInterceptor,
            multi: true
          }
    ],
    entryComponents: [
        SubscribeInfoModalComponent
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {
        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
            const config: OpenIdConfiguration = {
                stsServer: configResult.customConfig.stsServer,
                redirect_url: env.clientUrl,
                post_login_route: env.redirectRoute,
                post_logout_redirect_uri: env.clientUrl,
                client_id: env.clientId,
                scope: 'openid profile email',
                response_type: 'code',
                silent_renew: true,
                silent_renew_url: `${env.clientUrl}/${env.silentRenewRoute}`,
                log_console_debug_active: env.logConsoleDebugActive,
                disable_iat_offset_validation: true,
                auto_userinfo: false
            };

            this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
}
