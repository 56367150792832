import { ClientDto } from 'app/core/models/clientDto';

export class ALNIdentityAuthUser {
    preferred_username: string;
    email: string;
    userId: string;
    name: string;
    organizationName: string;
    profilePictureUrl: string;
    userRoles: string | string[];
    products: string | string[];
    allProducts: ClientDto[]
    sub: string;

    constructor(value: any) {
        Object.assign(this, value);
    }
}